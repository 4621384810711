import { template as template_3832fd364ff14a768540af7fee608828 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3832fd364ff14a768540af7fee608828(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
