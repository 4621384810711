import { template as template_07ee98df9db8455e915115860b3bca63 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_07ee98df9db8455e915115860b3bca63(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
