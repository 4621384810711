import { template as template_cd5e9095825646e2b83d65621426eb2d } from "@ember/template-compiler";
const WelcomeHeader = template_cd5e9095825646e2b83d65621426eb2d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
